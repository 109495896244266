import React from "react"
import { graphql } from "gatsby"

import { VideoDetails as Template } from "../components/Video/VideoDetails"

export const query = graphql`
  query($id: String!) {
    video: sanityVideo(_id: { eq: $id }) {
      ...GatsbyVideoFragment
    }
    # template: sanityTemplateVideo {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
  }
`

export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
